import {
  PinterestPinItCounterPosition,
  PinterestPinItSize,
} from './PinterestPinIt.types';

type Sizes = Record<'width' | 'height', number>;

export const constants = {
  a11yTitle: 'Pin to Pinterest',
} as const;

type ButtonSizes = Record<
  PinterestPinItCounterPosition,
  Record<PinterestPinItSize, Sizes>
>;

export const BUTTON_SIZES: ButtonSizes = {
  none: { small: { width: 50, height: 20 }, large: { width: 67, height: 28 } },
  above: { small: { width: 50, height: 56 }, large: { width: 67, height: 78 } },
  beside: {
    small: { width: 50, height: 20 },
    large: { width: 67, height: 28 },
  },
};
